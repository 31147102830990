import * as React from "react";
import { Col, Container, Row } from "reactstrap";

type BackgroundOption = "dark" | "light" | "primary" | "secondary";
type TextOption = "dark" | "light" | "white";

interface ContentSectionProps {
    fluid?: boolean;
    backgroundColor?: BackgroundOption;
    textColor?: TextOption;
    title?: string;
    subTitle?: string;
    narrow?: boolean;
    small?: boolean;
}

const ContentSection: React.FunctionComponent<ContentSectionProps> = ({
    children,
    fluid,
    backgroundColor,
    textColor,
    title,
    subTitle,
    narrow,
    small
}) => {
    let className: string = "content-section";

    if (narrow) className += " narrow";
    if (backgroundColor) className += ` bg-${backgroundColor}`;
    if (textColor) className += ` text-${textColor}`;

    return (
        <section className={className}>
            <Container fluid={fluid}>
                <Row className="justify-content-center">
                    <Col lg={small ? 8 : 12}>
                        {title && (
                            <h2 className="mb-5 section-title">
                                {title}

                                {subTitle && (
                                    <React.Fragment>
                                        <br />
                                        <small>{subTitle}</small>
                                    </React.Fragment>
                                )}
                            </h2>
                        )}
                        {children}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContentSection;
