import * as React from "react";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import orderingGuide from "../../assets/documents/ITES-3S-Tiber-Creek-Ordering-Guide.docx";

class Ites3s extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="ITES-3S" />

                <ContentSection narrow>
                    <h1 className="text-center">ITES-3S</h1>
                </ContentSection>

                <ContentSection title="About ITES-3S" small>
                    <p>
                        The Information Technology Enterprise Solutions - 3
                        Services (ITES-3S) contract is an Indefinite Delivery,
                        Indefinite Quantity (IDIQ) contract vehicle structured
                        to provide the full range of IT services and solutions
                        to the federal government. ITES-3S is managed by the{" "}
                        <a href="https://chess.army.mil">
                            Computer Hardware, Enterprise Software and Solutions
                            (CHESS)
                        </a>{" "}
                        program at Ft. Belvoir, VA.
                    </p>
                </ContentSection>

                <ContentSection
                    backgroundColor="secondary"
                    textColor="white"
                    title="Key Benefits"
                >
                    <ul>
                        <li>
                            Government-Wide Use - Army, DoD, and other federal
                            agencies
                        </li>
                        <li>No Contract Usage Fee</li>
                        <li>
                            Decentralized Ordering (All TORs issued via CHESS IT
                            e-mart -{" "}
                            <a
                                href="https://chess.army.mil"
                                className="text-reset"
                            >
                                https://chess.army.mil
                            </a>
                            )
                        </li>
                        <li>
                            Performance-Based Cost, T&amp;M, FFP, and Cost
                            Reimbursement (CR) Task Orders
                        </li>
                    </ul>
                </ContentSection>

                <ContentSection title="Overview: W52P1J-18-D-A097" narrow>
                    <p>
                        <strong>Customers</strong> – All DOD and Federal
                        Agencies
                    </p>
                    <p>
                        <strong>Mission</strong> – The ITES-3S scope will
                        include a full range of services and solutions necessary
                        for agencies to satisfy their enterprise infrastructure
                        and infrastructure goals with Information Technology
                        (IT) services worldwide. The scope includes the Command,
                        Control, Communications, Computers and Information
                        Management (C4IM) requirements.
                    </p>
                    <p>
                        <strong>Period of Performance</strong> – September 2018
                        – September 2027
                    </p>
                    <p>
                        <strong>$12B in Ceiling</strong> – no size limit on Task
                        Orders
                    </p>
                    <p>
                        <strong>0% Fee for use</strong>
                    </p>
                    <p>
                        <strong>Contract Types</strong> – All allowed
                        (FFP/CR/T&amp;M/Hybrids) – 147 labor categories
                    </p>
                </ContentSection>

                <ContentSection title="Scope" narrow>
                    <ul className="list-group">
                        <li className="list-group-item">
                            Cyber Security Services
                        </li>
                        <li className="list-group-item">
                            Business Process Reengineering
                        </li>
                        <li className="list-group-item">
                            Information Systems Security
                        </li>
                        <li className="list-group-item">
                            Information Assurance
                        </li>
                        <li className="list-group-item">
                            Information Technology Services
                        </li>
                        <li className="list-group-item">
                            Enterprise Design, Integration, and Consolidation
                        </li>
                        <li className="list-group-item">Education/Training</li>
                        <li className="list-group-item">
                            Program/Project Management
                        </li>
                        <li className="list-group-item">
                            Network Systems Operation and Maintenance
                        </li>
                        <li className="list-group-item">
                            Telecommunications/Infrastructure
                        </li>
                        <li className="list-group-item">IT Supply Chain</li>
                    </ul>
                </ContentSection>

                <ContentSection title="Ordering ITES-3S Services" narrow>
                    <p>
                        Download Tiber Creek's{" "}
                        <a
                            href="/assets/ITES-3S-Tiber-Creek-Ordering-Guide.docx"
                            download
                        >
                            ITES-3S Ordering Guide
                        </a>
                        .
                    </p>
                </ContentSection>

                <div className="mb-5">
                    <ContentSection
                        backgroundColor="secondary"
                        textColor="white"
                    >
                        <p className="mb-0 text-center">
                            To contact the ITES-3S team, please email{" "}
                            <a
                                href="mailto:Ites-3s@tibercreek.com"
                                className="text-reset"
                            >
                                Ites-3s@tibercreek.com
                            </a>
                        </p>
                    </ContentSection>
                </div>
            </Layout>
        );
    }
}

export default Ites3s;
